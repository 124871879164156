<template>
  <div id="top-sticky" class="bottom-border">
    <div class="wrap-width">
      <nav class="menu menu2 nav-rest">
        <div class="nav-border-wrap">
          <ul>
            <li><a href=".navigation" @click="navigate($event)">Work</a></li>
            <li><a href="#about" @click="navigate($event)">About</a></li>
            <li><a href="#contact" @click="navigate($event)">Contact</a></li>
          </ul>
        </div>
      </nav>
    </div>
  </div>
</template>
<script setup>
import { onMounted, watch } from "vue";
import { useNavStore } from "@/stores/navStore";

// REACTIVE PROPS FROM STORE/S
const navStore = useNavStore();

// METHODS

const scrolly = () => {
  var prevScrollpos = window.pageYOffset;
  window.onscroll = function () {
    var currentScrollPos = window.pageYOffset;
    if (
      prevScrollpos > currentScrollPos &&
      navStore.scrollTimeout == true
    ) {
      document.getElementById("top-sticky").classList.remove("topHidden");
      document.getElementById("top-sticky").classList.add("topShown");
    } else {
      document.getElementById("top-sticky").classList.remove("topShown");
      document.getElementById("top-sticky").classList.add("topHidden");
    }
    prevScrollpos = currentScrollPos;

    if (currentScrollPos == 0) {
      document.querySelector(".menu2").classList.remove("nav-scrolled");
      document.querySelector(".menu2").classList.add("nav-rest");
      document.getElementById("top-sticky").classList.add("bottom-border");
      document.getElementById("top-sticky").classList.remove("topHidden");
      document.getElementById("top-sticky").classList.add("topShown");
    } else {
      document.querySelector(".menu2").classList.remove("nav-rest");
      document.querySelector(".menu2").classList.add("nav-scrolled");
      document.getElementById("top-sticky").classList.remove("bottom-border");
    }
  };
};

const navigate = (event) => {
  event.preventDefault();
  var theTarget = event.target.getAttribute("href");
  document.querySelector(theTarget).scrollIntoView({
    block: "start",
    behavior: "smooth",
  });
};

  // MOUNTED

  onMounted(() => {
    scrolly();
  });

  // WATCHERS

  watch(() => navStore.scrollTimeout, () => {    
    scrolly();
  });
  
</script>
